@tailwind base;

@layer base {
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }
}

@tailwind components;

.container {
  width: 849pt !important;
  max-width: calc(100vw - (16pt * 2)) !important;
}
.container > .container-content {
  @apply max-w-full;
}
ul.list-none {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin: 0px;
}

kbd {
  box-shadow: 0 1px 1px rgb(12 13 14 / 15%), inset 0 1px 0 0 #fff;
  text-shadow: 0 1px 0 white;
  padding: 0.1em 0.6em;
  margin: 0 0.1em;
  @apply break-words border border-gray-500 bg-gray-200 rounded text-black-800 text-xs inline-block;
}

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
  body {
    line-height: 1.2;
  }
}

@layer utilities {
  .border-b-1 {
    @apply border-solid border-0 border-b;
  }
  .border-t-1 {
    @apply border-solid border-0 border-t;
  }
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .loading-dots:after {
    content: '  .';
    animation: dots 1s steps(5, end) infinite;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

@tailwind utilities;

.beta-hidden {
  @apply ring ring-warning-lighter relative;
}

.beta-hidden:hover::after {
  content: '✖️ Disabled in beta';
  display: inline-block;
  position: absolute;
  bottom: 50%;
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  transition: 0.3s;
  min-width: 83px;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  font-size: 8px;
}

.center-y {
  top: 50%;
  transform: translateY(-50%);
}

#intercom-container .intercom-app .intercom-launcher-frame {
  z-index: 999 !important;
}
.intercom-lightweight-app {
  z-index: 999 !important;
}
