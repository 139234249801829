@import url('./antd.light.css');

.ant-form-item-label > label {
  @apply text-accent-6 font-normal block text-base leading-10;
  padding-left: 1px;
}

.ant-table-thead > tr > th {
  @apply border-t border-b border-accent-2 border-solid border-l-0 border-r-0 text-xs text-accent-5 uppercase font-normal leading-loose;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  @apply border-l;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  @apply border-r;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-table-wrapper.border-none .ant-table-tbody > tr > td,
.ant-table-wrapper.border-none .ant-table-thead > tr > th {
  border: none !important;
}

.ant-table {
  background: transparent;
}

.ant-table-wrapper.table-dark .ant-table-thead > tr > th {
  @apply bg-black text-accent-1 border-solid border-black;
}

.ant-table-wrapper.table-dark table > thead > tr:first-child th:first-child,
.ant-table-wrapper.table-dark table > thead > tr:first-child th:last-child {
  @apply rounded-b-none;
}

.ant-table-wrapper.table-dark table > tbody > tr td:first-child {
  @apply border-l border-solid border-t-0 border-r-0;
  border-left-color: black;
}

.ant-table-wrapper.table-dark table > tbody > tr td:last-child {
  @apply border-r border-solid border-t-0 border-l-0;
  border-right-color: black;
}

.ant-table-wrapper.table-dark table > tbody > tr:last-child td {
  @apply border-b border-black;
}

.ant-table-wrapper.table-dark table > tbody > tr:last-child td:first-child {
  @apply rounded-bl-md;
}

.ant-table-wrapper.table-dark table > tbody > tr:last-child td:last-child {
  @apply rounded-br-md;
}

.ant-btn.border-none {
  @apply shadow-none;
}

.ant-popover.p-0 .ant-popover-inner-content {
  padding: 0;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  @apply bg-accent-2;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  @apply bg-accent-1;
}

.ant-tooltip-inner {
  @apply bg-blur-dark backdrop-blur;
}

.ant-form-item-label label {
  @apply font-medium;
}

.ant-form-item-label .ant-form-item-optional {
  @apply font-normal text-sm;
}

button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-primary:hover {
  @apply bg-black text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-warning:hover {
  @apply bg-warning text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-info:hover {
  @apply bg-info text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-success:hover {
  @apply bg-success text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-dark:hover {
  @apply bg-gray-900 text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-lightdark:hover {
  @apply bg-gray-600 text-white !important;
}
button.ant-btn.ant-btn-background-ghost:not(.outlined).ant-btn-danger:hover {
  @apply bg-error text-white !important;
}

/* SUCCESS NOTIFICATION */
.ant-notification-notice.ant-notification-notice-success:not(.notice-default) {
  @apply bg-success text-white;
}

.ant-notification-notice.ant-notification-notice-success:not(.notice-default) .ant-notification-notice-message {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-success:not(.notice-default) .ant-notification-notice-description {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-success:not(.notice-default) .ant-notification-notice-icon-success {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-success:not(.notice-default) .ant-notification-notice-close {
  @apply text-success-light hover:text-success-lighter;
}

/* ERROR NOTIFICATION */
.ant-notification-notice.ant-notification-notice-error:not(.notice-default) {
  @apply bg-red-500 text-white;
}

.ant-notification-notice.ant-notification-notice-error:not(.notice-default) .ant-notification-notice-message {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-error:not(.notice-default) .ant-notification-notice-description {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-error:not(.notice-default) .ant-notification-notice-icon-error {
  @apply text-white;
}

.ant-notification-notice.ant-notification-notice-error:not(.notice-default) .ant-notification-notice-close {
  @apply text-red-100 hover:text-red-200;
}

/* DISABLED FIELDSET */

.fieldset[disabled] input.ant-input[disabled].ant-input-disabled {
  @apply border-accent-2 bg-accent-1 !important;
}

.fieldset[disabled] .ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon {
  color: rgba(0, 0, 0, 0.25);
}

/* RESET MODAL IF YOU WANT TO CUSTOMIZE TO FOOTER PLACEMENT */
.reset-antd-modal .ant-modal-body {
  @apply p-0 !important;
}

.ant-badge.text-inherit .ant-badge-status-text {
  @apply text-inherit;
}
.ant-badge.text-top .ant-badge-status-text {
  @apply align-text-top;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  @apply bg-black-100;
}
